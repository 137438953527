import { Box, Spinner } from "@storyofams/react-ui";

import { createClient } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { Article } from "~components/common/Layout/SearchCards/Article";

export const RecipesSearch = ({ searchState }) => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipesItems = async (): Promise<any> => {
    try {
      //@ts-ignore
      let { data, error } = await supabase
        .rpc("search_recipes", {
          search_term: searchState,
        })
        .limit(5)
        .eq("published", true)
        .order("exact_match_score", { ascending: false })
        .order("relevance_score", { ascending: false })
        .order("fts_score", { ascending: false });
      if (error) return [];
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: recipeItems, isLoading } = useQuery(
    ["getRecipesItems", searchState],
    getRecipesItems,
    {
      enabled: !!searchState,
    }
  );
  if (!recipeItems && !recipeItems?.length) return null;
  if (isLoading) return <Spinner />;
  return (
    <Box>
      {recipeItems.map((item, i) => (
        <Box
          mt={i ? "12px" : "30px"}
          pb={"12px"}
          borderBottom={
            i !== recipeItems.length - 1 ? "2px solid grey200" : "none"
          }
          key={item.slug}
        >
          <Article isWindow article={item} />
        </Box>
      ))}
    </Box>
  );
};
