import React, { ReactNode } from "react";
import { Box, Flex, SystemProps, Icon, Text } from "@storyofams/react-ui";
import { useMeasure } from "react-use";
import { ArrowDown, ArrowUp } from "~components/common/Icons";

export interface AccordionProps extends SystemProps {
  title: string;
  children: ReactNode | string;
  close?: boolean;
  IsOpen?: boolean;
}

export const MenuAccordion = ({
  title,
  children,
  IsOpen,
  ...props
}: AccordionProps) => {
  const [ref, { height }] = useMeasure();

  return (
    <Box
      borderBottom="2px solid"
      borderBottomColor="grey200"
      textAlign="left"
      {...props}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        py={["16px", "12px"]}
        cursor="pointer"
      >
        <Box position={"relative"}>
          <Text
            fontSize={["12px", "15px"]}
            fontFamily={"DINPro"}
            fontWeight="500"
          >
            {title.toUpperCase()}
          </Text>
        </Box>

        <Flex>
          <Icon
            icon={IsOpen ? ArrowUp : ArrowDown}
            fontSize={["12px", "15px"]}
          />
        </Flex>
      </Flex>
      <Box
        maxHeight={IsOpen ? [`${height + 3 * 8}px`, `${height + 4 * 8}px`] : 0}
        transition="max-height ease 0.3s"
        overflow="hidden"
      >
        <Box
          mt={IsOpen ? [1, "4px"] : 0}
          pb={[3, "20px"]}
          transition="opacity ease 0.25s"
          opacity={IsOpen ? 1 : 0}
          ref={ref}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
