import { useState } from "react";
import { Box, Icon } from "@storyofams/react-ui";
import { Popover } from "react-tiny-popover";
import useMediaQuery from "~hooks/useMediaQuery";
import { countDiscount } from "~lib/shopify/utils/countDiscount";
import { Info } from "../Icons";
import { Text } from "../Text";

const DiscountInfo = ({ variant, discountTag, quantity }) => {
  const [showDiscounts, setShowDiscounts] = useState(false);
  const { twoPcs, fourPcs, sixPcs, twoDiscount, fourDiscount, sixDiscount } =
    countDiscount(variant, discountTag);
  const discount =
    quantity >= 4 ? (quantity >= 6 ? sixDiscount : fourDiscount) : twoDiscount;
  const matchesMobile: boolean = useMediaQuery("(max-width: 769px)");
  return (
    <Box alignItems="center" display="flex">
      <Text
        as="span"
        fontFamily="DINPro"
        fontStyle="normal"
        fontSize="16px"
        lineHeight="21px"
      >{`${discount}% stapelkorting`}</Text>
      <Popover
        isOpen={showDiscounts}
        padding={3}
        align={matchesMobile ? "end" : "start"}
        positions={["top"]}
        content={
          <Box
            px={3}
            py={2}
            border="1px solid grey400"
            backgroundColor={"white"}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Box>
              <Text as="span" fontWeight="medium">
                {twoPcs}
              </Text>
              <Text as="span"> p/s bij 2 stuks </Text>
              <Text as="span">{` (${twoDiscount}% korting)`}</Text>
            </Box>
            <Box>
              <Text as="span" fontWeight="medium">
                {fourPcs}
              </Text>
              <Text as="span"> p/s bij 4 stuks </Text>
              <Text as="span">{` (${fourDiscount}% korting)`}</Text>
            </Box>
            <Box>
              <Text as="span" fontWeight="medium">
                {sixPcs}
              </Text>
              <Text as="span"> p/s bij 6 stuks </Text>
              <Text as="span">{` (${sixDiscount}% korting)`}</Text>
            </Box>
          </Box>
        }
      >
        <Icon
          ml={1}
          fontSize={2.5}
          icon={Info}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setShowDiscounts((open) => !open);
            return false;
          }}
        />
      </Popover>
    </Box>
  );
};
export default DiscountInfo;
