import { Icon } from "@storyofams/react-ui";
import { Button } from "../../../components";
import { useShopify } from "../../../context";
import { Trash } from "../Icons";

export const RemoveButton = ({ variant, ...props }) => {
  const { removeLineItem } = useShopify();
  return (
    <Button
      ml={1.75}
      width={30}
      height={30}
      alignItems="center"
      justifyContent="center"
      variant="unstyled"
      onClick={(e) => {
        e.preventDefault();

        removeLineItem(
          variant.id,
          () => null,
          variant.merchandise.product.metafield ? variant.merchandise.id : ""
        );
      }}
      {...props}
    >
      <Icon icon={Trash} fontSize={2.25} />
    </Button>
  );
};
