import { memo, useMemo } from "react";
import { Box, Flex, Icon, Text } from "@storyofams/react-ui";
import { createClient } from "@supabase/supabase-js";
import { isSameYear } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { FormattedDate } from "react-intl";
import { useQuery } from "react-query";
import { Star } from "~components/common/Icons";

export const Article: React.FC<any> = memo(({ article, isWindow = false }) => {
  return (
    <Link
      prefetch={false}
      href={`/${article?.content?.thumbnail ? "blog" : "recipes"}/${
        article?.slug
      }`}
    >
      <Box>
        <Flex
          p={!isWindow && 1}
          cursor={"pointer"}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <Flex minWidth={0}>
            <Box
              borderRadius={"8px"}
              width={60}
              height={article.published_at && !isWindow ? 77 : 50}
              position={"relative"}
              mr="12px"
              overflow={"hidden"}
            >
              <Image
                quality={50}
                objectFit="cover"
                layout="fill"
                src={
                  article?.image ||
                  article?.content?.thumbnail?.filename + "/m/100x100"
                }
              />
            </Box>
            <Box flex={1} minWidth={0}>
              <Text
                mb="11px"
                mt="5px"
                fontFamily={"DINPro"}
                fontSize={"12px"}
                color={"#767473"}
              >
                {article?.content?.thumbnail ? "BLOG" : "RECIPE"}
              </Text>
              <Text
                fontFamily={"DINPro"}
                fontWeight={700}
                fontSize={"16px"}
                css={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {article?.name
                  ? article.name.toUpperCase()
                  : article?.title?.text?.toUpperCase() ||
                    article?.title_text?.toUpperCase()}
              </Text>
              <Flex>
                {article.published_at && !isWindow && (
                  <Text
                    as="span"
                    mt={"14px"}
                    display="block"
                    css={{ textTransform: "uppercase" }}
                    color="grey600"
                    fontSize={1.5}
                    fontWeight="medium"
                  >
                    <FormattedDate
                      day="numeric"
                      month="long"
                      value={article.published_at}
                      {...(!isSameYear(
                        new Date(),
                        new Date(article.published_at)
                      )
                        ? { year: "numeric" }
                        : {})}
                    />
                  </Text>
                )}
                {article?.tag_list && !!article?.tag_list.length && (
                  <Text
                    as="span"
                    display="block"
                    mt={"10px"}
                    css={{ textTransform: "uppercase" }}
                    color="grey600"
                    ml={3}
                    fontSize={1.5}
                    fontWeight="medium"
                  >
                    {article?.tag_list[0]}
                  </Text>
                )}
              </Flex>
            </Box>
          </Flex>
          {!!article.rating && (
            <Box
              display={"flex"}
              fontWeight="bold"
              alignItems="center"
              lineHeight={1.5}
            >
              <Text fontSize={2} pl={1} pt="1px">
                {article.rating}
              </Text>
              <Icon
                ml={0.5}
                fontSize={[1.75]}
                color="oldPink"
                icon={Star}
                pb={"1px"}
              />
            </Box>
          )}
        </Flex>
        {article?.difficulty && !isWindow && (
          <Box
            height="28px"
            width="100%"
            px={1}
            bg="#FCF9F7"
            fontWeight={600}
            fontSize="13px"
            borderBottomLeftRadius={"md"}
            borderBottomRightRadius={"md"}
            lineHeight={"16px"}
            borderTop="1px solid grey200"
            alignItems="center"
            whiteSpace={"nowrap"}
            display={"flex"}
          >
            <Box
              height="24px"
              pt={"2px"}
              fontSize="13px"
              fontWeight={500}
              mr={3}
            >
              {article?.difficulty?.text?.toUpperCase()}
            </Box>
            <Box
              height="24px"
              pt={"2px"}
              fontSize="13px"
              fontWeight={500}
              mr={3}
            >
              {article?.total_time} MIN.
            </Box>
            <Box
              height="24px"
              pt={"2px"}
              overflow="hidden"
              css={{ textOverflow: "clip" }}
              fontSize="13px"
              fontWeight={500}
            >
              {`${
                article.ingredients
                  ? article.ingredients.split(",").length
                  : article?.recipes_ingredients.length
              }
                     INGREDIENTEN`}
            </Box>
          </Box>
        )}
      </Box>
    </Link>
  );
});
