import { useRef, useState } from "react";
import { Box, Flex, Icon } from "@storyofams/react-ui";
import styled from "styled-components";
import { Cross, Search } from "~components/common/Icons";
import { Button } from "../../..";
import { RecipesCategoriesItems } from "./RecipesCategoriesItems";
import { RecipesSearch } from "./RecipesSearch";

export const StyledInput = styled(Box)`
  appearance: none;
  display: inline-block;
  background-color: white;
  min-height: 42px;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.grey200};
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.18s ease-in-out, background-color 0.18s ease-in-out;
  text-decoration: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey400};
    text-overflow: ellipsis;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
export const RecipesCategories = ({ close }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchState, setSearchState] = useState("");
  const setStateId = useRef<any>();
  const ref = useRef(null);

  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current);
    setStateId.current = setTimeout(() => {
      setSearchState(nextSearchState);
    }, 300);
    setSearchTerm(nextSearchState);
  };
  return (
    <Box overflowY={"auto"}>
      {!searchState && <RecipesCategoriesItems close={close} />}
      <Flex
        width={"100%"}
        mt={searchState ? 0 : "30px"}
        flexDirection={["column", "row"]}
      >
        <Box
          position="relative"
          width={["100", searchState ? "100%" : "50%"]}
          ref={ref}
          mr={[0, 2]}
        >
          <form
            noValidate
            autoComplete="off"
            action=""
            role="search"
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <StyledInput
              as="input"
              px={2}
              py={1}
              pr={5}
              fontSize={2}
              color="black"
              borderRadius="md"
              type="search"
              name="search"
              placeholder="Zoek naar recepten"
              value={searchTerm}
              onChange={(e: any) => {
                onSearchStateChange(e.target.value);
              }}
            />

            <Icon
              display="flex"
              alignItems="center"
              position="absolute"
              right={1.5}
              top={0}
              bottom={0}
              color="grey800"
              fontSize={2.5}
              as="button"
              type="button"
              icon={searchTerm ? Cross : Search}
              onClick={(e) => {
                e.stopPropagation();
                if (searchTerm) {
                  setSearchState("");
                  setSearchTerm("");
                }
              }}
            />
          </form>
        </Box>
        <Button
          display={searchState && "none"}
          mt={[1, 0]}
          width={["100", "50%"]}
          px={"0 !important"}
          variant="primary"
          py={"0 !important"}
          minHeight={"41px"}
          height={"41px"}
          as="a"
          href={"/recipes"}
        >
          Bekijk alle recepten
        </Button>
      </Flex>
      {searchState && <RecipesSearch searchState={searchState} />}
    </Box>
  );
};
